import React from 'react';
import './App.css';
import Footer from './features/footer/Footer';

function App() {
  return (
    <div className="App">
      <div className="warning">Site is currently under maintenance. Some links might not work and will be added shortly</div>
      <header className="App-header">
        <div className="app-title">Saasea</div>
      </header>
      <section className="hero-section">
        <p>Software developement and creating SaaS applications</p>
      </section>
      <Footer />
    </div>
  );
}

export default App;
